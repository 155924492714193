import React from "react"

import { TAuthRequest } from "models/Auth"

import { Modal  } from "components/Library/Modal/Modal"
import { Loader } from "components/Common/Loader"
import {
   MaskedInput,
   Clock,
   Box,
   Text,
   FormField,
   Button
} from "grommet"

import { IS_LOCAL } from "constants/globalConstants"
import { toast } from "react-toastify"

export const ConfirmModal = ({
   phone,
   password,
   confirm,
   setСonfirm,
   submitForm
} : {
   phone     : string,
   password  : string,
   confirm   : boolean,
   setСonfirm: React.Dispatch<React.SetStateAction<boolean>>,
   submitForm: (data: TAuthRequest) => Promise<void>
}) => {

   return (
      <Modal
         modal    = { confirm }
         setModal = { setСonfirm }
         heading  = "Подтверждение"
      >
         { IS_LOCAL 
            ? (
               <Box>
                  <Text
                     textAlign = 'center'
                     as        = 'div'
                     margin    = { { bottom: 'medium' } }
                  >
                     Нажмите кнопку на контроллере
                  </Text>

                  <Loader />

                  <Box
                     align  = "center"
                     margin = { { top: 'large' } }
                     height = { { min: 'auto' } }
                  >
                     <Clock
                        type = "digital"
                        run  = "backward"
                        time = "T00:01:00"
                        size = 'large'
                        onChange = { time => {
                           if (time == 'T0:0:0') {
                              setСonfirm (false)
                              toast.error ('Время ожидания вышло')
                           }
                        } }
                     />
                  </Box>
               </Box> 
            ) : (
               <Box>
                  <Text
                     textAlign = 'center'
                     as        = 'div'
                     margin    = { { bottom: 'medium' } }
                  >
                        Введите код из SMS (1234)
                  </Text>

                  <FormField
                     label   = "Код"
                     htmlFor = "masked-code"
                     name    = "code"
                  >
                     <MaskedInput
                        id   = "masked-code"
                        name = "code"
                        mask = { [{
                           length     : 4,
                           regexp     : /^[0-9]$|^[0-9][0-9]$|^[0-9][0-9][0-9]$|^[0-9][0-9][0-9][0-9]$/,
                           placeholder: 'xxxx'
                        }] }
                        value = '1234'
                     />
                  </FormField>

                  <Button
                     primary
                     label   = 'Подтвердить'
                     justify = "center"
                     onClick = { () => submitForm ({
                        phone   : phone,
                        password: password
                     }) }
                  />
               </Box>
            )
         }
      </Modal>
   )
}
