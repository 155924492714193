// Custom Hook for fetching chart data

import { useCallback, useState } from 'react'
import { getTagChart        } from 'api/tagchart'
import { processChartData   } from './processChartData'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'

import { TTag } from 'models/Tag'
import {
   ChartData,
   Point,
   ScaleOptionsByType,
   CartesianScaleTypeRegistry,
} from 'chart.js'

export const useChartData = (
   tag      : TTag,
   interval : number,
   dBegin   : string | string[],
   dEnd     : string | string[],
   fullData : boolean,
   groupData: boolean
) => {

   const [options, setOptions] = useState <{
      labels  : ChartData['labels'],
      datasets: ChartData <'line', (number | Point)[]> ['datasets'],
      scales  : _DeepPartialObject<{ [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry> }>
   }> ()
   const [loading, setLoading] = useState (true)
 
   const fetchChartData = useCallback(() => {

      setLoading (true)

      getTagChart ([tag.id_tag], dBegin, dEnd, fullData, groupData)
      .then    (result => setOptions (processChartData (tag, result, interval, fullData)))
      .finally (() => setLoading (false))

   }, [tag, dBegin, dEnd, fullData, groupData])
 
   return { options, loading, fetchChartData }
}
