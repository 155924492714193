import React, { useContext } from 'react'
import { observer } from 'mobx-react'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TControl             } from 'models/ControlPanel'

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { CheckBox, ResponsiveContext } from 'grommet'

import { useWaitCommand       } from 'hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'

export const ControlToggle = observer (({
   alias,
   control,
   watchdog,
   sendCommand
}: {
   alias      : string,
   control    : TControl,
   watchdog   : boolean
   sendCommand: (
      a: string,
      c: number,
      v?: number
   ) => void
}) => {

   const option = control.options
   .find (option => option.state == controllerStateStore?.state
   .find (state => state.name == control.id)?.value)

   const size = useContext (ResponsiveContext)
   const { controlCommand, wait } = useWaitCommand (sendCommand)
   const {
      style,
      className,
      disabled,
      label
   } = useCorrelation (control)

   return (
      <ControlFabric wait = { wait }>
         <CheckBox
            label     = { label ?? size != 'small' ? <span style = { { textAlign: 'right' } }>{ option?.label }</span> : '' }
            checked   = { control.look ? eval (control.look) (option.state) : !!option.state }
            onChange  = { () => watchdog
                                ? controlCommand (alias, option.command)
                                : sendCommand    (alias, option.command) }
            disabled  = { disabled }
            style     = { style }
            className = { className }
            reverse
            toggle
         />
      </ControlFabric>
   )
})