import { api } from "api/client"
import { TSample } from "models/Sample"

export const getSamples = async (
   id_tags: number[]
): Promise<TSample[]> => {

   const url = '/sample/?' + id_tags.reduce ((acc, id_tag) => {
      acc += `id_tags[]=${id_tag}&`
      return acc
   }, '')

   return await api.send ('GET', url)
}