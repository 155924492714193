import React, { createContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { useDateRange } from './hooks/useDateRange'
import { useChartData } from './hooks/useChartData'
import { TTag         } from 'models/Tag'

import { Box           } from 'grommet'
import { ChartControls } from './components/ChartControls'
import { ChartDisplay  } from './components/ChartDisplay'

export const ChartContext = createContext (null)

export const TagChart = observer (({ tag }: { tag: TTag }) => {

   const [dBegin, setDBegin] = useState <string | string[]> ()
   const [dEnd  , setDEnd  ] = useState <string | string[]> ()
   
   const [interval , setInterval ] = useState (1)
   const [fullData , setFullData ] = useState (false)
   const [groupData, setGroupData] = useState (false)

   const chartContext = {
      dBegin   , setDBegin,
      dEnd     , setDEnd,
      interval , setInterval,
      fullData , setFullData,
      groupData, setGroupData,
   }

   useDateRange ({ interval, setDBegin, setDEnd })
   const { options, loading, fetchChartData } = useChartData (tag, interval, dBegin, dEnd, fullData, groupData)
 
   useEffect(() => {
      if (dBegin && dEnd) fetchChartData ()
   }, [dBegin, dEnd, fullData, groupData])
 
   return (
      <Box
         width     = 'large'
         height    = 'medium'
         direction = 'column'
         justify   = 'between'
      >
         <ChartContext.Provider value = { chartContext }>
            <ChartControls
               tag            = { tag }
               fetchChartData = { fetchChartData }
            />
         </ChartContext.Provider>
         <ChartDisplay
            options = { options }
            loading = { loading }
         />
      </Box>
   )
})