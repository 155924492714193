import React from "react"
import { TProjectItem } from "models/Project"

import {
   Card,
   CardFooter,
   CardHeader,
   CardBody,
   Text
} from "grommet"
import { LinkButton } from "components/Library/components/LinkButton"

export function ProjectCard ({ project }: { project: TProjectItem }) {

   return (
      <LinkButton to = { `/${project.id_project}` } >
         <Card
            pad            = "medium"
            gap            = "medium"
            round          = "xsmall"
            background     = { (project.id_workspace === 2) ? "red" : "baseBackground" }
            onClick        = { () => false }
            hoverIndicator = { "backgroundCardHover" }
         >
            <CardHeader
               align   = "center"
               justify = "start"
            >
               <Text
                  size   = "medium"
                  weight = "bold"
               >
                  { project.name }
               </Text>
            </CardHeader>

            <CardBody
               align   = "start"
               justify = "start"
            >
               <Text
                  size   = "small"
                  weight = "normal"
               >
                  { project.remark }
               </Text>
            </CardBody>

            <CardFooter
               align   = "center"
               justify = "start"
            >
               <Text
                  size   = "small"
                  weight = "normal"
               >
                  { new Date (project.dset).toLocaleString () }
               </Text>
            </CardFooter>
         </Card>
      </LinkButton>
   )
}
