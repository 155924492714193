import { makeAutoObservable  } from 'mobx'
import { TCurrentUser        } from 'models/CurrentUser'
import { getCurrentUser      } from 'api/user'

export class CurrentUserStore {
   currentUserData: TCurrentUser | null = null
   currentUserIsLoading = false

   constructor () { makeAutoObservable (this) }

   get currentUserExists ()
   {
      return !!(this.currentUserData?.id_state && !this.currentUserIsLoading)
   }

   getCurrentUserData ()
   {
      return getCurrentUser ()
      .then (userData => this.setUser (userData))
      .finally (() => this.setLoading (false))
   }

   setUser (userData)
   {
      this.currentUserData = userData
   }

   clearCurrentUserData ()
   {
      this.currentUserData = null
   }

   setLoading (isLoading: boolean)
   {
      this.currentUserIsLoading = isLoading
   }
}
