import { api } from "api/client"
import { TChartSample } from "models/Sample"

export const getTagChart = async (
   id_tags  : number[],
   DBegin   : string | string[],
   DEnd     : string | string[],
   fullData?: boolean,
   groupData?  : boolean
): Promise<TChartSample[]> => {

   const requestTags = id_tags.reduce ((acc, id_tag) => {
      acc += `id_tags[]=${id_tag}&`
      return acc
   }, '')

   return await api.send ('GET', `/tag/chart_dev/?${requestTags}dbegin=${DBegin}&dend=${DEnd}${fullData ? '&fulldata' : ''}${groupData ? '&groupData' : ''}`);
}