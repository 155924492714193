import { TCurrentUser, TFormEditCurrentUserData } from "models/CurrentUser"
import { TError       } from "models/Error"
import { api          } from "api/client"
import { TAuthRequest } from "models/Auth"

export const getCurrentUser = async (): Promise<TCurrentUser> => {
   return await api.send ("GET", "/user/")
}

export const reg = async (
   data: TAuthRequest
): Promise<TCurrentUser> => {
   return await api.send ("POST", "/registration/", data)
}

export const reset = async (
   data: TAuthRequest
): Promise<TCurrentUser> => {
   return await api.send ("PUT", "/registration/", data)
}

export const editCurrentUser = async (
   data: TFormEditCurrentUserData
): Promise<TCurrentUser> => {
   return await api.send ("PUT", "/user/", data)
}

export const changeUserPassword = async (
   data: {
      currentPassword: string,
      newPassword    : string
   }
): Promise<TCurrentUser | TError> => {
   return await api.send ("PUT", "/user/", data)
}