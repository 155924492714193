import React, { useMemo } from "react"

import { Button, CardBody, CardFooter, CheckBox, Text } from "grommet"
import { TCustomBlock   } from "models/ControlPanel"
import { ControlFabric  } from "components/Library/ControlFabric/ControlFabric"
import { useWaitCommand } from "hooks/useWaitCommand"

export const TargetEnable = ({
   block,
   targetValue,
   targetIsEnable,
   setTargetIsEnable,
   setEdit,
   sendCommand
}: {
   block            : TCustomBlock,
   targetValue      : number,
   targetIsEnable   : boolean,
   setTargetIsEnable: React.Dispatch<React.SetStateAction<boolean>>,
   setEdit          : (b: boolean) => void,
   sendCommand      : (
      a: string,
      c: number,
      v: number) => void
}) => {

   const stateCommandOn  = useMemo (() => block.controls
   .flatMap (control => control.options).find (option => /Состояние/.test (option.label) && option.state == 1)?.command,
   [block.controls])

   const stateCommandOff = useMemo (() => block.controls
   .flatMap (control => control.options).find (option => /Состояние/.test (option.label) && option.state == 0)?.command,
   [block.controls])

   const {
      controlCommand,
      wait
   } = useWaitCommand (sendCommand)

   return (
      <>
         <CardBody>
            <Text
               textAlign = 'center'
               weight    = 'bolder'
               size      = "large"
               margin    = { { vertical: "xsmall" } }
            >
               { targetValue } { block.sign }
            </Text>
         </CardBody>

         <CardFooter
            justify = 'between'
            margin  = { { top: "small" } }
         >
            { (stateCommandOn && stateCommandOff) &&
               <ControlFabric
                  wait = { wait }
               >
                  <CheckBox
                     checked  = { targetIsEnable }
                     onChange = { e =>  {
                        controlCommand (block.alias, e.target.checked
                                                     ? stateCommandOn
                                                     : stateCommandOff)
                        setTargetIsEnable (e.target.checked)
                     } }
                     toggle
                  />
               </ControlFabric>
            }

            <Button
               primary
               reverse
               label   = "Изменить"
               onClick = { () => setEdit (true) }
               fill    = 'horizontal'
            />
         </CardFooter>
      </>
   )
}