import React, { useEffect } from "react"
import { observer             } from "mobx-react"
import { useParams            } from "react-router-dom"

import { devicesStore         } from "store/DevicesStore"
import { settingsStore        } from "store/SettingsStore"
import { controllerStateStore } from "store/ControllerStateStore"
import { controlPanelStore    } from "store/ControlPanelStore"
import { sampleStore          } from "store/SampleStore"

import { Box                  } from "grommet"
import { Loader               } from "components/Common/Loader"
import { Greeting             } from "components/Common/Greeting"
import { Schedules            } from "components/Blocks/Schedules/Schedules"
import { ControlBlocks        } from "components/Blocks/ControlBlocks/ControlBlocks"
import { IndicationBlocks     } from "components/Blocks/IndicationBlocks/IndicationBlocks"
import { Groups               } from "components/Blocks/Groups/Groups"
//import { riftStateStore } from "./Blocks/CustomBlocks/RiftSchedules/store/RiftsStore"

let isRetry = false 

export const ControlPanel = observer (() => {

   const { idProject, idPanel } = useParams ()

   useEffect (() => {

      if (isRetry == false) {

         devicesStore.getDevices (Number (idProject))
         .then (() => {
            controlPanelStore.getState    (Number (idProject), Number (idPanel))
            controllerStateStore.getState (Number (idProject))
            settingsStore.getSettings     (Number (idProject))
            sampleStore.getState          (Number (idProject))
         })
      }

      isRetry = true
   }, [idProject])

   //useEffect (() => {
   //   if (controllerStateStore.state.length > 0)
   //      riftStateStore.setRifts (controllerStateStore.state)
   //}, [controllerStateStore.state])

   //console.log (toJS (sampleStore.samples))

   if (devicesStore.loading         ||
       controlPanelStore.loading    ||
       controllerStateStore.loading ||
       settingsStore.loading) return <Loader />

   else return (
      <Box pad = "large">
         <Greeting />

         { controllerStateStore.state?.length > 0
            ? (
               <>
                  { (controlPanelStore.tagBlocks?.length > 0 ||
                     controlPanelStore.indicationBlocks?.length > 0 ||
                     devicesStore.tags?.length > 0) &&
                     <IndicationBlocks /> }
                  <Groups />
                  <ControlBlocks />
               </>
            )
            : <Box align = "center">Контроллер не подключен</Box>
         }

         <Schedules />
      </Box>
   )
})