import { makeAutoObservable } from "mobx"

import { TSample            } from "models/Sample"
import { rootStore          } from "./RootStore"
import { devicesStore       } from "./DevicesStore"
import { controlPanelStore  } from "./ControlPanelStore"

import { getSamples         } from "api/sample"
import { TAG_TIMER          } from "constants/globalConstants"

class SampleStore {

   loading = false
   samples   : TSample[] = []
   idProject: number
   interval  : ReturnType <typeof setInterval>

   constructor () { makeAutoObservable (this) }

   getState (idProject: number)
   {
      this.setLoading (true)

      if (this.idProject != idProject) {

         this.idProject = idProject
         clearInterval (this.interval)
         this.samples = []
      }

      this.setState ()
      this.setIntervals ()
   }

   setIntervals ()
   {
      if (devicesStore.tags.length > 0) {

         this.interval = setInterval (async () => {

            if (rootStore.currentUserStore.currentUserData &&
               this.idProject != null) this.setState ()
            else clearInterval (this.interval)
         }, (TAG_TIMER))
      }
   }

   async setState ()
   {
      const tags = [
         ...devicesStore.tags
         .filter (tag => controlPanelStore.tagBlocks
         ?.some (block => block.id == String (tag.id) && block.alias == tag.alias)),
         ...devicesStore.tags
         .filter (tag => controlPanelStore.tagControlBlocks
         ?.some (block => block.id == String (tag.id) && block.alias == tag.alias))
      ]

      if (devicesStore.tags?.length > 0 && tags?.length > 0) {

         const id_tags = tags.map (({ id_tag }) => id_tag)
         const samples = await getSamples (id_tags)
         if (samples) this.setSamples (samples)

      } else this.setLoading (false)
   }

   setSamples (samples)
   {
      this.samples = samples
      this.setLoading (false)
   }

   setLoading (isLoading: boolean)
   {
      this.loading = isLoading
   }
}

export const sampleStore = new SampleStore ()