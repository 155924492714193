import { useEffect, useState } from "react"

import { TControl, TGroup     } from "models/ControlPanel"
import { correlationsStore    } from 'store/CorrelationsStore'
import { controllerStateStore } from "store/ControllerStateStore"

export const useCorrelation = (
   control: TControl | TGroup,
   value? : number | boolean
) : {
   render   : boolean,
   style    : object,
   className: string,
   disabled : boolean,
   label    : string
} => {

   const [render   , setRender   ] = useState <boolean> ()
   const [disabled , setDisabled ] = useState <boolean> ()
   const [style    , setStyle    ] = useState <object>  ()
   const [className, setClassName] = useState <string>  ()
   const [label    , setLabel    ] = useState <string>  ()

   useEffect (() => {

      if (control && control.type != 'Group') {

         let func

         if (value != undefined) {
            func = control.options
            .find (option => option.state == Number (value))?.func
         }
         else {
            func = control.options
            .find (option => option.state == controllerStateStore?.state
            .find (state  => state.name == control.id)?.value)?.func
         }

         if (func && !correlationsStore.correlations
         .find (correlation => correlation?.target == control?.rel)?.disabled)
            correlationsStore.setCorrelation (
               control.id, 
               func
            )
      }
   }, [controllerStateStore.state, value])

   useEffect (() => {

      const relation = correlationsStore.correlations
      .find (correlation => correlation?.target == control?.rel)

      if (relation) {
         setStyle     (relation?.style)
         setClassName (relation?.class)
         setDisabled  (relation?.disabled)
         setRender    (relation?.render)
         setLabel     (relation?.label)
      }
   }, [correlationsStore.correlations])

   return {
      render,
      style,
      className,
      disabled,
      label
   }
}