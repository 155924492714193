import { TControlValue        } from "./TControlValue"
import { controllerStateStore } from "store/ControllerStateStore"
import { controlPanelStore    } from "store/ControlPanelStore"
import { toast                } from "react-toastify"

import { CONTROL_TIMER } from "constants/globalConstants"

export const setControl = (
   control: TControlValue,
   setWait: React.Dispatch<React.SetStateAction<boolean>>
) => {

   if (control) {

      const newValue = control?.value ?? 
      controlPanelStore.getControlOptionByCommand    (control?.command)?.state ?? 
      controlPanelStore.getIndicationOptionByCommand (control?.command)?.state

      let timer = true
      setWait (true)

      setTimeout (() => timer = false, CONTROL_TIMER)

      const toastId  = toast.loading ('Ждём ответа контроллера')
      const interval = setInterval   (() => {

         const oldValue = controllerStateStore.state.find (state => state.name == control?.name)?.value
         controllerStateStore.queryStateCommand (control.project, control.alias)

         if (oldValue != undefined) {

            if ((controlPanelStore.getControlByCommand (control.command)?.type === 'Toogle' && oldValue != newValue) ||
                (controlPanelStore.getControlByCommand (control.command)?.type !== 'Toogle' && oldValue == newValue)) {
               toast.update (toastId, { render: "Значение изменено", type: "success", isLoading: false, autoClose: 1000 })
               setWait (false)
               return clearInterval (interval)
            }

            if (timer === false) {

               setWait (false)
               toast.update (toastId, { render: "Контроллер не изменил значение", type: "error", isLoading: false, autoClose: 1000 })
               return clearInterval (interval)
            }
         }
         else {
            setWait (false)
            toast.update (toastId, { render: "Состояние параметра не отпределено", type: "error", isLoading: false, autoClose: 2000 })
            return clearInterval (interval)
         }
      }, 500)
   }
}