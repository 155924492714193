import { useCallback, useState } from "react"
import { setControl            } from "components/Library/ControlFabric/hooks/setControl"
import { controlPanelStore     } from "store/ControlPanelStore"
import { setTarget             } from "components/Library/ControlFabric/hooks/setTarget"
import { useParams             } from "react-router-dom"

export const useWaitCommand = (sendCommand: (
   alias  : string,
   command: number,
   value? : number
) => void) => {

   const { idProject } = useParams ()
   const [wait, setWait] = useState (false)

   const controlCommand = useCallback (async (
      alias  : string,
      command: number,
      value? : number
   ) => {

      sendCommand (alias, command, value)

      if (controlPanelStore.getControlByCommand         (command) ||
          controlPanelStore.getIndicationBlockByCommand (command)) {
         setControl ({
            project: Number (idProject),
            alias,
            name: controlPanelStore.getControlByCommand         (command)?.id
            ??    controlPanelStore.getIndicationBlockByCommand (command)?.id,
            command,
            value
         }, setWait)
      }
      else if (controlPanelStore.getTargetSchedulesByCommand (command)) {
         setTarget ({
            project: Number (idProject),
            alias,
            name: controlPanelStore.getTargetSchedulesByCommand (command)?.id,
            command,
            value
         }, setWait)
      }
   }, [controlPanelStore.controlBlocks, controlPanelStore.targetBlocks])

   return { controlCommand, wait }
}