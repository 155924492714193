import { TRoutes } from 'models/Routes'

import { Main          } from 'containers/Main/Main'
import { AuthContainer } from 'containers/Auth/Auth'
import { Page404       } from 'components/Common/Page404'
import { ControlPanel  } from 'components/ControlPanel'
import { Registration  } from 'components/Auth/Registration'
import { ResetPassword } from 'components/Auth/ResetPassword'

const routes: TRoutes = [
   {
      path     : '/login',
      component: AuthContainer,
      isOuter  : true,
   },
   {
      path     : '/registration',
      component: Registration,
      isOuter  : true,
   },
   {
      path     : '/resetpassword',
      component: ResetPassword,
      isOuter  : true,
   },
   {
      path     : '/',
      component: Main,
   },
   {
      path     : '/:IdProject',
      component: ControlPanel,
   },
   {
      path     : '*',
      component: Page404
   },
]

export const isOuter = (pathname: string): boolean => {

   return routes
   .filter (route => route.isOuter)
   .some   (route => route.path === pathname)
}