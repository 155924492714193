import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { controllerStateStore  } from 'store/ControllerStateStore'
import { TControl              } from 'models/ControlPanel'

import { Box, RangeInput, Text } from 'grommet'
import { ControlFabric         } from "components/Library/ControlFabric/ControlFabric"

import { useWaitCommand        } from "hooks/useWaitCommand"
import { useCorrelation        } from 'hooks/useCorrelation'

export const ControlRangeInput = observer (({
   alias,
   control,
   watchdog,
   sendCommand
}: {
   alias      : string,
   control    : TControl,
   watchdog   : boolean,
   sendCommand: (
      a : string,
      c : number,
      v?: number
   ) => void
}) => {

   const [rangeValue, setRangeValue] = useState <number> (controllerStateStore.state
   ?.find (state => state.name == control.id)?.value)
   const { controlCommand, wait } = useWaitCommand (sendCommand)
   const {
      style,
      className,
      disabled,
      label
   } = useCorrelation (control)

   useEffect (() => {
      setRangeValue (
         controllerStateStore.state
         ?.find (state => state.name == control.id)?.value
      )
   }, [controllerStateStore?.state])

   return (
      <Box direction = 'column'>

         { control.options.map (option => (

            <ControlFabric
               key  = { option.command }
               wait = { wait }
            >
               <Box>

                  { rangeValue != undefined &&
                     <Box
                        justify = 'end'
                        fill    = 'horizontal'
                     >
                        <Text alignSelf = 'center'>
                           { `${label ?? option.label}: ${rangeValue} ${control.sign ?? '%'}` }
                        </Text>
                     </Box>
                  }

                  <Box
                     margin = { { top: 'medium' } }
                     fill   = 'horizontal'
                  >
                     <RangeInput
                        value      = { control.look ? eval (control.look) (rangeValue) : rangeValue }
                        max        = { option.max }
                        min        = { option.min }
                        onChange   = { event => setRangeValue (Number (event.target.value)) }
                        onTouchEnd = { () => watchdog
                                             ? controlCommand (alias, option.command, rangeValue)
                                             : sendCommand    (alias, option.command, rangeValue) }
                        onMouseUp  = { () => watchdog
                                             ? controlCommand (alias, option.command, rangeValue)
                                             : sendCommand    (alias, option.command, rangeValue) }
                        disabled   = { disabled }
                        style      = { style }
                        className  = { className }
                     />
                  </Box>
               </Box>
            </ControlFabric>
         )) }
      </Box>
   )
})
