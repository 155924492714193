import { devicesStore } from "store/DevicesStore"
import { TChartSample } from "models/Sample"
import { TTag         } from "models/Tag"

export const processChartData = (
   tag     : TTag,
   result  : TChartSample[],
   interval: number,
   fullData: boolean
) => {

   const data: {[key: string]: TChartSample[]} = {}
   let oldSample = null

   result.forEach (sample => {

      if (!data[sample.id_tag]) data[sample?.id_tag] = []

      if (devicesStore.tags
      .find (tag => tag.id_tag === sample.id_tag).id_tagtype === 5) {

         if (sample.id_tag === oldSample?.id_tag &&
             oldSample && sample.sample && interval === 1) {

            const adjustedSample = fullData
                  ? new Date (new Date (sample.dsample).setMinutes (new Date (sample.dsample).getMinutes () - 1))
                  : new Date (new Date (sample.dsample).setHours   (new Date (sample.dsample).getHours   () - 1))

            data[sample.id_tag].push({
               sample : oldSample.sample,
               dsample: adjustedSample.toLocaleString('sv-SE', {
                  year  : 'numeric',
                  month : '2-digit',
                  day   : '2-digit',
                  hour  : '2-digit',
                  minute: '2-digit',
                  ...(fullData && { second: '2-digit' })
               }) + (fullData && "." + new Date (sample.dsample).getMilliseconds ()),
               id_tag: sample.id_tag
            })
         }

         oldSample = sample
      }

      data[sample.id_tag].push (sample)
   })

   if (Object.keys (data)?.length > 0) {

      const datum  = Object.values (data)
      ?.flatMap (samplesGroup => samplesGroup)
      .flatMap  (({ dsample }) => dsample)
      .sort     ((a, b) => new Date (a).getTime () - new Date (b).getTime ())

      const labels = [...new Set (datum.map (dsample => {

         if (interval === 1) {

            return fullData
                   ? new Date (dsample).toLocaleTimeString ('ru-RU')
                   : new Date (dsample).toLocaleString ('ru-RU', { hour: 'numeric' })

         } else return new Date (dsample).toLocaleDateString ('ru-RU')
      }))]

      const datasets = Object.entries (data).map (([key, samplesGroup]) => {

         const color = devicesStore.tags.find (({ id_tag }) => id_tag === Number (key)).color

         return {
            data           : datum
            .map (dsample => samplesGroup.find (sample => sample.dsample === dsample)?.sample || null),
            borderColor    : color,
            backgroundColor: color,
            yAxisID        : key,
         }
      })

      const scales = datasets.reduce ((acc, dataset, i) => {

         const tag = devicesStore.tags.find (({ id_tag }) => id_tag === Number(dataset.yAxisID))

         return {
            ...acc,
            [dataset.yAxisID]: {
               type    : 'linear',
               display : true,
               position: (i === 0) ? 'left' : 'right',
               grid    : { drawOnChartArea: false },
               title   : {
                  color  : tag.color,
                  display: true,
                  text   : `${tag.name} (${tag.sign})`
               }
            }
         }
      }, {
         x: { title: {
            display: true,
            text   : (interval === 1) ? 'Время' : 'День'
         } }
      })

      return { labels, datasets, scales }
   }
}